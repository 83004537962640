import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../state/store';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { Modal } from 'flowbite-react';

import {
    ComponentSideBar,
    ComponentMainTop,
    ComponentMainBody,
    ComponentMainBottom,
} from '../components/Components';
import {
    userSetURLParams,
    setIsMdScreen,
    setActiveComponent,
    mdScreenSize,
    setActiveSubComponent,
    setShowSideBar,
    resetRoute,
    userLogout,
    DecodedToken,
    userUpdateToken,
    refreshUserState,
    userAcceptTerms,
    setIsFreshGuest
} from '../state/user/userSlice'
import {
    courseSetURLParams,
    courseGetOutline,
    refreshCourseState,
    unsetCourseState,
} from '../state/course/courseSlice'
import { TwButton, TwLoading } from '../components/_items';
import { PrivateComponentPrivacy, PrivateComponentTerms } from '../components/_subComponents';


const Coursely = () => {
    // Url params for the initial state
    const location = useLocation();
    let navigate = useNavigate();
    // global states and disptach
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    const sideBarWidth = 260
    const [mainBodyWidth, setMainBodyWidth] = useState(window.innerWidth - sideBarWidth);
    const [privacyTermsModal, setPrivacyTermsModal] = useState(user.hid && user.accepted_terms ? false : user.hid ? true : false) //
    const [termsModal, setTermsModal] = useState(false)
    const [privacyModal, setPrivacyModal] = useState(false)

    useEffect(() => {
        if (user.hid && !user.accepted_terms) {
            setPrivacyTermsModal(true)
        } else {
            setPrivacyTermsModal(false)
        }

    }, [dispatch, user.hid, user.accepted_terms, location]); // Empty dependency array means this effect will only run once, when the component mounts.

    useEffect(() => {
        const runFunction = () => {
            const access_token_info = user.accessToken && typeof user.accessToken == 'string' ? jwtDecode(user.accessToken) as DecodedToken : null
            const refresh_token_info = user.refreshToken && typeof user.refreshToken == 'string' ? jwtDecode(user.refreshToken) as DecodedToken : null
            if (
                access_token_info && refresh_token_info
            ) {
                var timestamp = Math.floor(Date.now() / 1000)
                if (
                    'exp' in refresh_token_info &&
                    refresh_token_info.exp > timestamp
                ) {
                    // Uncomment for testing
                    //toast.info(
                    //    `now: ${timestamp}, exp: ${refresh_token_info.exp}, ${refresh_token_info.exp - timestamp}, ${access_token_info.exp - timestamp}`
                    //)

                    if (
                        user.refreshToken && typeof user.refreshToken == 'string' &&
                        'exp' in access_token_info &&
                        access_token_info.exp < timestamp
                    ) {
                        dispatch(userUpdateToken(
                            { refreshToken: user.refreshToken }
                        ))
                    }
                } else {
                    dispatch(userLogout())
                    dispatch(unsetCourseState())
                }
            }
        };
        // Set up the interval
        const intervalId = setInterval(runFunction, 2000); // 5000 ms = 5 seconds
        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch, user, user.accessToken, user.refreshToken]); // Empty dependency array means this effect will only run once, when the component mounts.


    useEffect(() => {// updates the course id on location change
        dispatch(refreshCourseState())
        dispatch(refreshUserState())
    }, [dispatch])

    useEffect(() => {// updates the course id on location change
        if (user.routeTo) {
            dispatch(resetRoute())
            navigate(user.routeTo)
        }
    }, [dispatch, user.routeTo, navigate])

    const urlParams = new URLSearchParams(location.search);
    const checkoutSuccess_param = urlParams.get('checkoutSuccess');
    const { coursename, chapter_lnum, topic_lnum } = useParams();
    useEffect(() => {
        if (typeof (window as any).gtag === 'function' && checkoutSuccess_param) {
            (window as any).gtag('event', 'conversion', {
                'send_to': 'AW-16589853661/h7aGCJ-ivMsZEN2n1OY9',  // Replace with your conversion ID
                'value': 1.0,
                'currency': 'GBP',
            });
            setTimeout(function() {
                window.location.href = '/home';  // Replace with your target URL
            }, 3000);
        }
    }, [checkoutSuccess_param]);

    useEffect(() => {
        if (typeof (window as any).gtag === 'function' && user.is_fresh_guest) {
            (window as any).gtag('event', 'conversion', {
                'send_to': 'AW-16589853661/h7aGCJ-ivMsZEN2n1OY9',  // Replace with your conversion ID
                'value': 0.0,
                'currency': 'GBP',
            });
            dispatch(setIsFreshGuest())
        }
    }, [dispatch, user.is_fresh_guest]);
    useEffect(() => {// updates the course id on location change
        if (coursename && chapter_lnum && topic_lnum) {
            dispatch(courseSetURLParams({ courseName: coursename, chapter_lnum: chapter_lnum, topic_lnum: topic_lnum }));
        } else if (coursename && !chapter_lnum && !topic_lnum) {
            dispatch(courseSetURLParams({ courseName: coursename, chapter_lnum: null, topic_lnum: null }));
        }
        dispatch(userSetURLParams(location));
    }, [dispatch, location, coursename, chapter_lnum, topic_lnum])

    useEffect(() => {// adds a signup wall for the chat interface
        if (user.uidb64 && user.token && user.rtype) {
            dispatch(setActiveComponent('SubComponentUserRegistration'))
            if (user.rtype === 'reset') {
                dispatch(setActiveSubComponent('PrivateComponentPasswordReset'))
            }
            if (user.rtype === 'activate') {
                dispatch(setActiveSubComponent('PrivateComponentActivateAccount'))
            }
        }
    }, [dispatch, user.uidb64, user.token, user.rtype])

    useEffect(() => {// adds a signup wall for the chat interface
        if (
            !(course.courseId && course.lessonNumber) &&
            !(user.uidb64 && user.token && user.rtype) &&

            (
                user.activeComponent !== 'SubComponentGeneralTutorChatInterface' &&
                user.activeComponent !== 'SubComponentChatInterface' &&
                user.activeComponent !== 'SubComponentUserRegistration' &&
                user.activeComponent !== 'SubComponentStripeDashboard' &&
                user.activeComponent !== 'SubComponentCourseIntroduction'
            )
        ) {
            dispatch(setActiveComponent('SubComponentExplore'))
        }
    }, [dispatch, course.courseId, course.lessonNumber, user.uidb64, user.token, user.rtype, user.activeComponent])
    useEffect(() => {// adds a signup wall for the chat interface
        if (
            user.activeComponent === 'SubComponentGeneralTutorChatInterface' ||
            user.activeComponent === 'SubComponentChatInterface'
        ) {
            dispatch(setShowSideBar(true))
        } else {
            dispatch(setShowSideBar(false))
        }
    }, [dispatch, user.activeComponent, user.isMdScreen])
    useEffect(() => {// adds a signup wall for the chat interface
        function transformCourseName(str: string): string {
            return str
                // Replace hyphens with spaces
                .replace(/-/g, ' ')
                // Capitalize each word
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        }
        if (course.courseId && course.lessonNumber && window.location.pathname === 'chat') {
            dispatch(setActiveComponent('SubComponentChatInterface'))
            if (course.courseLessonIntroduction && 'chapter_name' in course.courseLessonIntroduction && 'topic_name' in course.courseLessonIntroduction) {
                const title = `${transformCourseName(course.courseLessonIntroduction.course_name)} | ${course.courseLessonIntroduction.topic_name}`
                const truncatedTitle: string = title.length > 70
                    ? title.slice(0, 70) + ' ...'
                    : title;
                document.title = truncatedTitle

                let metaTag = document.querySelector('meta[name="description"]');
                const metaContent: string = course.courseLessonIntroduction['meta_description']
                const truncatedMetaContent: string = metaContent.length > 150
                    ? metaContent.slice(0, 150) + ' ...'
                    : metaContent;

                if (metaTag) {
                    metaTag.setAttribute("content", truncatedMetaContent);
                } else {
                    const meta = document.createElement("meta");
                    meta.name = "description";
                    meta.content = truncatedMetaContent;
                    document.head.appendChild(meta);
                }
            }
        }
    }, [dispatch, course.courseId, course.lessonNumber, course.courseLesson, course.courseLessonIntroduction])
    useEffect(() => {// adds a signup wall for the chat interface
        function transformCourseName(str: string): string {
            return str
                // Replace hyphens with spaces
                .replace(/-/g, ' ')
                // Capitalize each word
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        }
        if (course.courseId && !course.lessonNumber && window.location.pathname === 'chat') {
            dispatch(setActiveComponent('SubComponentCourseIntroduction'))
            if (course.courseCourseIntroduction && 'course_name' in course.courseCourseIntroduction) {
                const title = `Free ${transformCourseName(course.courseCourseIntroduction.course_name)} online course`
                const truncatedTitle: string = title.length > 70
                    ? title.slice(0, 70) + ' ...'
                    : title;
                document.title = truncatedTitle

                let metaTag = document.querySelector('meta[name="description"]');
                const metaContent: string = course.courseCourseIntroduction['meta_description']
                const truncatedMetaContent: string = metaContent.length > 150
                    ? metaContent.slice(0, 150) + ' ...'
                    : metaContent;

                if (metaTag) {
                    metaTag.setAttribute("content", truncatedMetaContent);
                } else {
                    const meta = document.createElement("meta");
                    meta.name = "description";
                    meta.content = truncatedMetaContent;
                    document.head.appendChild(meta);
                }
            }
        }
    }, [dispatch, course.courseId, course.lessonNumber, course.courseCourseIntroduction])


    useEffect(() => {// Home is the explore page
        if (window.location.pathname === '/home') {
            const search = window.location.search;
            navigate(`/home${search}`);
            dispatch(setActiveComponent('SubComponentExplore'))
        }
    }, [dispatch, navigate])


    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            const width = window.innerWidth;

            if (width <= mdScreenSize) {
                dispatch(setIsMdScreen(true));
            } else {
                dispatch(setIsMdScreen(false));
            }
        }

        // Set up
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch]);
    useEffect(() => {
        const handleResize = () => setMainBodyWidth(window.innerWidth - sideBarWidth);

        // Attach resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, [sideBarWidth]);

    useEffect(() => {// updates the course outline on course id change
        if (course.courseId) {
            dispatch(courseGetOutline({ course_id: course.courseId }))
        }
    }, [dispatch, course.courseId])

    useEffect(() => {// adds a signup wall for the chat interface
        if (!user.activeComponent) {
            dispatch(setActiveComponent('SubComponentExplore'))
        }
    }, [dispatch, user.hid, user.activeComponent])
    // static params  and style
    let sidebar_display_style = 'flex flex-col'
    let toggle_sidebar_style = `w-full md:!w-[${sideBarWidth}px] md:!min-w-[${sideBarWidth}px] md:!max-w-[${sideBarWidth}px]`
    let toggle_body_style = `hidden md:flex md:flex-col md:w-[${mainBodyWidth}px]`
    let mainBodyStyle: { width: string } | {} = {
        width: `${mainBodyWidth}px`,
    };
    if (user.showSideBar === false) {
        sidebar_display_style = 'hidden'
        toggle_sidebar_style = ''
        toggle_body_style = 'flex flex-col w-full'
        mainBodyStyle = {}
    }

    return (
        <>
            {checkoutSuccess_param ? (
                <Modal show={String(checkoutSuccess_param) === '1'} theme={
                    {
                        "header": {
                            "close": {
                                "base": "hidden",
                                "icon": ""
                            }
                        },
                    }
                }>
                    <Modal.Header>Welcome you are now a member !</Modal.Header>
                    <Modal.Body>
                        You will be redirected shortly.
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

            <Modal show={termsModal} onClose={() => setTermsModal(false)}>
                <Modal.Header>Terms Of Service</Modal.Header>
                <Modal.Body>
                    <PrivateComponentTerms />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setTermsModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
            <Modal show={privacyModal} onClose={() => setPrivacyModal(false)}>
                <Modal.Header>Privacy Policy</Modal.Header>
                <Modal.Body>
                    <PrivateComponentPrivacy />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setPrivacyModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
            <div className={`flex md:flex-row-reverse !max-w-12/12 m-auto px-0 text-[9px] min-h-[100dvh] max-h-[100dvh] overflow-hidden rounded-r-[20px]`}>
                <div className={`${toggle_body_style} m-0 text-[9px] p-[5px] min-h-[100dvh] max-h-[100dvh]`} style={mainBodyStyle}>
                    <div className={'flex flex-row m-0 text-[9px] w-full h-auto p-2'}>
                        <ComponentMainTop>
                        </ComponentMainTop>
                    </div>
                    <div className={`
    				flex flex-col px-0 text-[12px] h-full w-full bg-slate-850 overflow-scroll
    				`} id='chat_page_top'>
                        <div className='basis-12/12 h-full'>
                            <ComponentMainBody>
                            </ComponentMainBody>
                        </div>
                    </div>
                    <div className={'flex flex-row m-0 p-0 text-[9px] w-full relative'}>
                        <ComponentMainBottom>
                        </ComponentMainBottom>
                    </div>
                </div>
                <div className={`${sidebar_display_style} ${toggle_sidebar_style} m-0 p-0 text-[11px] bg-slate-950 min-h-[100dvh] max-h-[100dvh] text-slate-50 rounded-r-[20px]`} style={!user.isMdScreen ? { width: sideBarWidth } : { width: '90%' }}>
                    <div id='scrollableDiv' className='basis-12/12 overflow-scroll rounded-r-[20px]'>
                        <ComponentSideBar>
                        </ComponentSideBar>
                    </div>
                </div>
            </div >
            <Modal show={privacyTermsModal} theme={
                {
                    "header": {
                        "close": {
                            "base": "hidden",
                            "icon": ""
                        }
                    },
                }
            }>
                <Modal.Header>Terms of Service, Privacy and Cookie concent</Modal.Header>
                <Modal.Body>
                    <p className="mb-2">By using this site, you agree to the following:</p>
                    <ul className="list-disc list-inside space-y-2 ml-4">
                        <li>You accept our <strong onClick={() => setTermsModal(true)} className='text-blue-500 hover:cursor-pointer hover:underline ml-1'>Terms of Service</strong>.</li>
                        <li>You consent to our <strong onClick={() => setTermsModal(true)} className='text-blue-500 hover:cursor-pointer hover:underline ml-1'>Privacy Policy</strong>.</li>
                        <li>You agree to our use of <strong>cookies</strong> to improve your experience.</li>
                    </ul>
                    <p className="mt-4">All three agreements are required to use the site.</p>
                </Modal.Body>
                <Modal.Footer>
                    <TwButton onClick={() => {
                        if (!user.loading.userAcceptTerms && user.accessToken) {
                            dispatch(userAcceptTerms({ accessToken: user.accessToken }));
                        }
                    }}>
                        {user.loading.userAcceptTerms ? (
                            <TwLoading />
                        ) : ('I accept')}
                    </TwButton>
                </Modal.Footer>
            </Modal>
            <Modal show={termsModal} onClose={() => setTermsModal(false)}>
                <Modal.Header>Terms Of Service</Modal.Header>
                <Modal.Body>
                    <PrivateComponentTerms />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setTermsModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
            <Modal show={privacyModal} onClose={() => setPrivacyModal(false)}>
                <Modal.Header>Privacy Policy</Modal.Header>
                <Modal.Body>
                    <PrivateComponentPrivacy />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setPrivacyModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Coursely
